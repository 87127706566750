import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    paginatedAndroidAppVersionsListing: {
        total: 0,
        paginationOffset: null,
        pageIndex: 1,
        pageSize: 15,
        data: [],
    },
    paginatedIosAppVersionsListing: {
        total: 0,
        paginationOffset: null,
        pageIndex: 1,
        pageSize: 15,
        data: [],
    },
}
export const appVersionSlice = createSlice({
    name: 'promotedAppVersion',
    initialState,
    reducers: {
        setAndroidAppVersionsList: (state, action) => {
            state.paginatedAndroidAppVersionsListing = action.payload
        },
        setIOSAppVersionsList: (state, action) => {
            state.paginatedIosAppVersionsListing = action.payload
        },
    },
})

export const { setAndroidAppVersionsList, setIOSAppVersionsList } =
    appVersionSlice.actions

export default appVersionSlice.reducer
