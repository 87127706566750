import { combineReducers } from '@reduxjs/toolkit'
import appVersionSlice from './appVersionSlice'
import promotedBundleSlice from './promotedBundleSlice'

const reducer = combineReducers({
    appVersionSlice,
    promotedBundleSlice,
})

export default reducer
