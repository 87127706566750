import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedTab: '',
    paginatedAndroidBundles: {
        total: 0,
        paginationOffset: null,
        pageIndex: 1,
        pageSize: 15,
        data: [],
    },
    paginatedIosBundles: {
        total: 0,
        paginationOffset: null,
        pageIndex: 1,
        pageSize: 15,
        data: [],
    },
    selectedBundle: null,
}
export const bucketSlice = createSlice({
    name: 'bundle',
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setAndroidBundles: (state, action) => {
            state.paginatedAndroidBundles = action.payload
        },
        setIOSBundles: (state, action) => {
            state.paginatedIosBundles = action.payload
        },
        setBundleLists: (state, action) => {
            state.androidBundles = action.payload.androidBundles
            state.iosBundles = action.payload.iosBundles
        },
        setSelectedBundle: (state, action) => {
            state.selectedBundle = action.payload
        },
        resetBundleData: () => initialState,
    },
})

export const {
    setBundleLists,
    resetBundleData,
    setAndroidBundles,
    setIOSBundles,
} = bucketSlice.actions

export default bucketSlice.reducer
