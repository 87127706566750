import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import {
    apiSignIn,
    apiSignUp,
    apiVerifyOtp,
    apiSendOtp,
    apiForgotPassword,
    apiVerifyForgotPassLink,
    apiResetPassword,
    apiGetUserData,
    apiLogoutUser,
} from 'services/AuthService'
import {
    onSignInSuccess,
    onSignOutSuccess,
    setOtpToken,
} from 'store/auth/sessionSlice'
import { resetOrgData } from 'store/dashboard/orgSlice'
import { resetProjectData } from 'store/dashboard/projectSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY, REF_URL_KEY } from 'constants/app.constant'
import { useLocation, useNavigate } from 'react-router-dom'
import useQuery from '../useQuery'
import store from '../../../store'
import { resetBucketData } from '../../../store/dashboard/bucketSlice'
import { resetBundleData } from '../../../store/bundle/bundleSlice'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()
    const { state } = useLocation()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const authenticateUser = async () => {
        try {
            const resp = await apiGetUserData()
            dispatch(setUser(resp.data))
        } catch (e) {
            dispatch(onSignOutSuccess())
            dispatch(resetOrgData())
            dispatch(resetProjectData())
            dispatch(setUser(initialState))
            navigate(appConfig.unAuthenticatedEntryPath)
        }
    }

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                const { otpToken } = resp.data
                dispatch(setOtpToken(otpToken))
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate('/verify-otp', {
                    state: {
                        redirectUrl,
                    },
                })
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message:
                    errors?.response?.data?.errors?.data[0]?.message ||
                    errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { otpToken } = resp.data
                dispatch(setOtpToken(otpToken))
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate('/verify-otp', {
                    state: {
                        redirectUrl,
                    },
                })
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message:
                    errors?.response?.data?.errors?.data[0]?.message ||
                    errors.toString(),
            }
        }
    }
    const signOut = async () => {
        apiLogoutUser()
        dispatch(onSignOutSuccess())
        dispatch(resetOrgData())
        dispatch(resetProjectData())
        dispatch(resetBucketData())
        dispatch(resetBundleData())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const sendOtp = async () => {
        try {
            const { auth } = store.getState()
            const { otpToken } = auth.session
            if (!otpToken) {
                return navigate('/sign-in')
            }
            const resp = await apiSendOtp({ otpToken })
            return {
                status: 'success',
                message: 'Code Sent',
            }
        } catch (e) {
            if (
                e?.response?.data?.errors?.data[0]?.message?.includes(
                    'TokenExpiredError'
                )
            ) {
                navigate('/sign-in')
            }
            return {
                status: 'failed',
                message:
                    e?.response?.data?.errors?.data[0]?.message || e.toString(),
            }
        }
    }

    const verifyOtp = async (otp) => {
        try {
            const { auth } = store.getState()
            const { otpToken } = auth.session
            if (!otpToken) {
                return navigate('/sign-in')
            }
            const { data } = await apiVerifyOtp({ otpToken, otp })

            if (data.user && data?.accessToken) {
                dispatch(setUser(data.user))
                dispatch(onSignInSuccess(data?.accessToken))
            }

            dispatch(setOtpToken(''))

            let redirectUrl = state.redirectUrl
            if (redirectUrl === 'verify-otp')
                navigate(appConfig.authenticatedEntryPath)
            else
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
            return {
                status: 'success',
                message: 'Code Verified',
            }
        } catch (e) {
            if (
                e?.response?.data?.errors?.data[0]?.message?.includes(
                    'TokenExpiredError'
                )
            ) {
                return navigate('/sign-in')
            }
            return {
                status: 'failed',
                message:
                    e?.response?.data?.errors?.data[0]?.message || e.toString(),
            }
        }
    }

    const forgotPassword = async (data) => {
        try {
            await apiForgotPassword({ email: data })
            setTimeout(() => navigate('/sign-in'), 1000)
            return {
                status: 'success',
                message: 'Link sent',
            }
        } catch (e) {
            return {
                status: 'failed',
                message:
                    e?.response?.data?.errors?.data[0]?.message || e.toString(),
            }
        }
    }

    const verifyForgotPassLink = async (data) => {
        try {
            await apiVerifyForgotPassLink({ requestToken: data })
            navigate('/reset-password')
        } catch (e) {
            // setTimeout(() => navigate('/sign-in'), 1000)
            return {
                status: 'failed',
                message: 'Link expired or invalid',
            }
        }
    }

    const forgotPassReset = async (data) => {
        try {
            await apiResetPassword(data)
            setTimeout(() => navigate('/sign-in'), 3000)
            return {
                status: 'success',
                message: 'Password reset success',
            }
        } catch (e) {
            return {
                status: 'failed',
                message: 'Something went wrong',
            }
        }
    }

    return {
        authenticated: token && signedIn,
        authenticateUser,
        signIn,
        signUp,
        signOut,
        verifyOtp,
        sendOtp,
        forgotPassword,
        verifyForgotPassLink,
        forgotPassReset,
    }
}

export default useAuth
