import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import dashboard from './dashboard'
import bundle from './bundle'
import promoted from './promoted'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        dashboard,
        locale,
        bundle,
        promoted,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
