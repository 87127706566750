const appConfig = {
    // apiPrefix: 'https://stallion-api.redhorse.tech/api/v1',
    apiPrefix: 'http://localhost:8000/api/v1',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
