import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    paginatedPromotedBundlesList: {
        total: 0,
        paginationOffset: null,
        pageIndex: 1,
        pageSize: 15,
        data: [],
    },
    selectedBundleVersion: null,
}
export const promotedBundleSlice = createSlice({
    name: 'promotedBundles',
    initialState,
    reducers: {
        setPromotedBundlesList: (state, action) => {
            state.paginatedPromotedBundlesList = action.payload
        },
        setSelectedBundleVersion: (state, action) => {
            state.selectedBundleVersion = action.payload
        },
    },
})

export const { setPromotedBundlesList, setSelectedBundleVersion } =
    promotedBundleSlice.actions

export default promotedBundleSlice.reducer
